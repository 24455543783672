:root {
  --dark: #eee;
  --light: #fff;
  --darkgrey: #333;
  --purple: #6CABDD;
  --red: #f731ed;
  --pink: #d6181f;
  --gold: #fdd431;
}
.App {
  text-align: center;
  background-color: var(--light) !important;
  color: #333;
  min-height: 100vh;
}

.nav-body-split {
  width: 100vw;
  height: 0rem;
  background-color: var(--dark);
}

.line-divider {
  width: 100vw;
  height: 0.1rem;
  background-color: #eee;
}

.button-links {
  font-family: "Soccer League Italic";
  font-size: 1.5rem;
  letter-spacing: 0.1rem;
}
.soccer {
  font-family: "Soccer League Italic";
}

.wa {
  align-items: center;
  border: 1px solid var(--light);
  background: #25d366;
  padding: 5px;
  border-radius: 10px;
  height: 50px;
}
.swal-h1 {
  font-size: 3rem;
}

.navbar {
  position: sticky;
  top: 0;
  z-index: 9999;
}
/* .navbar-brand img {
  filter: invert(0.4);
} */

.bg-light {
  background: white;
}

.purple {
  background: var(--purple);
}

.pink {
  background: var(--pink);
}

.sticky-bottom{
  position: sticky;
  bottom: 0;
  margin-top: 20px;
}