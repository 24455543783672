.menu-option {
  height: 8rem;
  text-align: left;
}

.menu-option .row {
  height: 100%;
}

.mo-left-tile {
  font-size: 1.5rem;
  letter-spacing: 0.1rem;
  padding: 1rem;
  display: flex;
  align-items: center;
}
.mo-left-tile h1 {
  font-size: 1.5rem;
}

.mo-right-tile {
  padding: 1rem;
  font-family: inherit;
  font-size: 1.1rem;
  letter-spacing: 0.1rem;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: rgba(0, 0, 0, 0.1);
  /* Tint color */
  background-blend-mode: multiply;
}

.mo-right-tile h1 {
  font-family: inherit;
  font-size: 1.3rem;
  font-weight: lighter;
}

.mo-right-tile a {
  align-self: center;
  font-style: italic;
  border: solid #fff 1px;
  border-radius: 50%;
  padding: 5px;
}

.bg-purple {
  background-color: var(--purple);
}
.bg-purple .mo-left-tile, .bg-purple .mo-right-tile, .bg-pink .mo-left-tile, .bg-pink .mo-right-tile{
  color: #fff;
}

.bg-red {
  background-color: var(--red);
}

.bg-pink {
  background-color: var(--pink);
}

.bg-gold {
  background-color: var(--gold);
}
