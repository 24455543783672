h1 {
  font-family: "Soccer League Italic";
  font-size: 4rem;
  letter-spacing: 0.1rem;
}
.small-h1 {
  font-size: 2.5rem;
}

.page-header {
  padding: 2rem;
}

a {
  color: inherit;
}

.dark-bg {
  background-color: var(--dark);
}

.white-bg {
  background-color: #fff;
}

.negative-mt {
  margin-top: -1.3rem;
}
.left {
  text-align: left;
}
