@font-face {
  font-family: 'Soccer League Italic';
  src: local('Soccer League Italic'), url(./fonts/soccer-league.italic.ttf);
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}
body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Soccer League Italic', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}