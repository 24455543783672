.ChallengeBadge{
    background: #FAFBFB 0% 0% no-repeat padding-box;
    border: 1px solid #3847501A;
    border-radius: 4px;
    padding-top: 1rem;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.ChallengeBadge__img{
    max-width: min(50%, 15rem);
    margin: 0 auto;
}
.ChallengeBadge__img > * {
    width: 100%;
    height: auto;
}

.ChallengeBadge.incomplete .ChallengeBadge__img{
    filter: grayscale(1);
    opacity: .25;
}
.ChallengeBadge__meta{
    color: #333;
}