.ChallengeBadge__container{
    display: grid;
    flex-wrap: wrap;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 1rem;
    margin-bottom: 1rem;
    padding-top:3rem;
}

@media (min-width:798px){
    .ChallengeBadge__container {
        grid-template-columns: repeat(4, 1fr);
    }
}


.ChallengeBadge__container:after, .ChallengeBadge__container:before {
    content: none;
 }