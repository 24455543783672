.FixtureCard.selected {
  background-color: var(--purple);
  color: #fff;
}
.vs {
  display: flex;
}
.vs span {
  font-family: "Soccer League Italic";
  align-self: center;
}
.FixtureCard div .when {
  font-weight: bolder;
  padding-bottom: 20px;
  font-size: 0.8rem;
}
.predicted-score {
  font-family: "Soccer League Italic";
  font-size: 2.5rem;
  color: white;
  padding: 20px 0;
}
.predicted-text,
.actual-text {
  align-self: center;
}
.actual-score {
  font-family: "Soccer League Italic";
  font-size: 3rem;
  color: white;
  padding: 20px 0;
  margin-top: 2px;
}
.FixtureCard {
  color: var(--darkgrey);
}

.bg-purple .underlined {
  color: var(--dark);
}
.fixture-card-header {
  color: var(--dark);
}

.where {
  margin-top: 10px;
  font-size: 0.7rem;
}

.change-predictions{
  font-size: 0.7rem;
  margin: 0 auto;
}