.summary-text {
    font-size: 1.5rem;
    align-self: center;
    font-family: 'Soccer League Italic';
}

.summary-value {
    align-self: center;
    font-size: 1.5rem;
}

.splittoggle{
    width: 10%;
}