

.stats-container {
    text-align: center;
    padding: 10px 0px;
}

.stats-value {
    font-size: 2em;
    font-weight: 700;
}

.divider {
    height: 2px;
    width: 200px;
    background-color: #fff;
    margin: 0 auto;
}

.stats-breakdown {
    text-align: center;
}

.stats-breakdown .pagetitle {
    font-size: 1.2em;
}

.reverse {
    background-color: var(--light);
    color: #fff;
}

.reverse .pagetitle {
    color: #fff;
}

.recent-placing {
    padding: 12px;
    border-radius: 5px;
    margin: 10px auto;
    background-color: #fff;
    max-width: 300px;
    color: #555;
}

.smallpagetitle,
.recent-placing h1 {
    margin: 0;
    font-size: 1.2em;
    font-weight: 700;
    color: #555;
}

.recent-placing h2 {
    margin: 0;
    font-size: 1em;
    font-weight: 700;
    color: #555;
}

.recent-placing-position {
    font-size: 3em;
    color: #333;
    font-weight: 600;
}

.primarybg{
    background-color: var(--pink);
    color: white;
}