.kittysummary{
    color: white;
    text-align: center;
    padding-bottom: 70px;
}

.kittysummary .venue{
    color: white;
}

.kittysummary .buttonholder{
    margin: auto;
    width: 60%;
    justify-content: center;
}

.kittysummary .highlight{
    background: var(--light);
    padding: 7px 20px;
    border-radius: 6px;

}

.memberheader, .memberrow{
    display: grid;
    grid-template-columns: 40vw 10vw 10vw;
    margin:0 10vw;
    align-items: center;
    padding: 17px 20px;
    grid-gap: 10px;
}

.memberrow{
    background: white;
    border-bottom: 1px solid #e8e8e8;
}
.memberrow:nth-child(1){
    box-shadow: 0px 40px 80px rgba(0, 0, 0, 0.07);
    border-radius: 20px;
    position: relative;
}

.memberheader{
    color: white;
    font-size: 14px;
}

.memberheader h4{
    font-size: 0.9em;
}

.user{
    display: flex;
    align-items: center;
    line-height: 1em;
}

.user .profile{
width: 30px;
min-width: 30px;
height: 30px;
font-size: 12px;
margin-right: 10px;
}

.username{
    font-weight: 700;
    font-size: 0.8em;
}

.membertable{
    margin-top: -180px;
}

.points{
   text-align: center;
}

.username{
    -ms-word-break: break-all;
    word-break: break-all;
    -webkit-hyphens: auto;
   -moz-hyphens: auto;
        hyphens: auto;
}

.status{
    text-align: center;
}

.explaination{
    font-size: 1.2rem;
}
.hash-span{
    font-size: 0.8rem;
}