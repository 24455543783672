.howtoplay{
  text-align: left;
  width: 70%;
  margin: auto;
  font-size: 1em;
}
.howtoplay ul li {
    text-align: left;
    line-height: 1.6em;
    margin-bottom: 10px;
  }
  

  .howtoplay h2{
    font-weight: 700;
    font-size: 1.3em;
    line-height: 1.7em;
  }

  .faq h2{
    color: var(--dark);
    font-weight: 700;
  }