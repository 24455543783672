@media all and (min-width: 480px) {
  .Signup form {
    margin: 0 auto;
    max-width: 320px;
  }
}

.Signup form span.help-block {
  font-size: 14px;
  padding-bottom: 10px;
  color: #999;
}

.login {
  color: black;
  display: flex;
  font-size: 12px;
  justify-content: center;
  padding-bottom: 20px;
}

.login a {
  margin-left: 5px;
  font-weight: 600;
  color: black;
}

.signupterms {
  color: var(--dark);
  margin-top: -60px;
  margin-bottom: 20px;
}

.signupterms a {
  font-size: 0.6em;
}
